.errorPageCardContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.errorPageCardWrapper {
  margin: 20px 0;
  border-radius: 10px;
  border-top: 4px solid #000;
  overflow: hidden;
}

.errorPageCard {
  width: 520px;
  height: 610px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #d9dbde;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 120px 80px 116px 80px;
  box-sizing: border-box;
}

.errorPageCard .title {
  margin-top: 32px;
}

.errorPageCard .title span {
  margin-left: 10px;
  position: relative;
  top: 6px;
}

.errorPageCard .title {
  display: flex;
  flex-wrap: nowrap;
  font-size: 24px;
  font-weight: 500;
}

.errorPageCard .text {
  font-size: 16px;
  margin-top: 26px;
  line-height: 21px;
}

.errorPageCard .regards {
  font-size: 16px;
  margin-top: 16px;
  line-height: 21px;
}

.errorPageCard .divider {
  display: block;
  width: 60px;
  height: 1px;
  margin-top: 40px;
  background-color: #d9dbde;
}

@media screen and (max-height: 640px) {
  .errorPageCard {
    padding: 80px 80px 76px 80px;
    height: 460px;
  }
}
