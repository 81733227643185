.container {
}

.flex {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
}

.avatar {
  position: relative;
}

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.name {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.phone {
  font-size: 12px;
  line-height: 20px;
  color: #969799;
}

.status {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.link {
  position: relative;
  height: 50px;
  font-size: 14px;
  border-top: 1px solid #d9dbde;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  line-height: 0;
  text-decoration: none;
  color: #5385ec;
}

.textLink {
  position: relative;
}

.arrowLink {
  position: relative;
  transform: translateX(3px);
}
