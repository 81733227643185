.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeff2;
  padding: 15px 0;
}

.container:first-of-type {
  padding-top: 0px;
}

.container:last-of-type {
  border-bottom: none;
  padding-bottom: 0px;
}

.info {
  display: flex;
  align-items: center;
}

.desc {
  position: relative;
  margin-left: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.name {
  color: #000000;
}

.link {
  text-decoration: none;
  color: #969799;
  transition: 0.15s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.link:hover {
  border-bottom: 1px solid #000000;
}
