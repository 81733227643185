.wrapper {
  width: 100%;
  max-width: calc(100% - 24px);
}

.avatar {
  position: relative;
  margin-right: 10px;
  float: left;
}

.content {
  position: relative;
  left: 8px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.name {
  height: 24px;
  display: flex;
  align-items: center;
  width: 165px;
  color: #1b1e26;
  margin-bottom: 6px;
}

.name span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message {
  color: #7d7e80;
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
}

.unread.message {
  font-weight: bold;
}
