.layout {
  margin-top: 85px;
  padding-right: 24px;
  width: 100%;
  max-width: 604px;
}

.header {
  display: block;
  border-bottom: 1px solid #e1e3e6;
  padding-bottom: 30px;
}

.title {
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.desc {
  position: relative;
  color: #646566;
  line-height: 18px;
  padding-top: 25px;
}

.formLayout {
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.footer {
  position: relative;
  margin: 48px 0;
  /*position: absolute;*/
  /*bottom: 0;*/
  /*left: 0;*/
}
