.content {
  position: relative;
  width: 100% !important;
  display: block;
  height: 100%;
}

.isLoading {
  position: relative;
  opacity: 0.35 !important;
  pointer-events: none !important;
}

.loader {
  position: absolute;
  display: flex;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  z-index: 10;
}
