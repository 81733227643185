.inputs {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  height: 54px;
  border-bottom: 1px solid #e1e3e6;
}

.inputs > label {
  margin-top: 0;
}

.inputs:first-child {
  padding-top: 0;
}

.inputs:last-of-type {
  border-bottom: none;
}

.inputs > div {
  margin-top: 0;
}

.inputs div {
  margin-bottom: 0 !important;
}

.fields {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.close {
  opacity: 0.5 !important;
  pointer-events: none;
}

.fields > div {
  margin-top: 0;
  margin-left: 10px;
  width: 100px !important;
}

.disabled {
  pointer-events: none !important;
}

.disabled [tabindex] {
  pointer-events: none;
  border-color: #edeff2;
  background: #edeff2;
}
