.message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
  font-size: 14px;
}

.lastInTimeGroup {
  margin-bottom: 20px;
}

.firstInTimeGroup {
  margin-top: 20px;
}

.lastInTimeGroup + .firstInTimeGroup {
  margin-top: 20px;
}

.reverse {
  flex-direction: row-reverse;
}
