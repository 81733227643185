.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.content:first-child {
  height: 48px !important;
}
