.details {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  flex: 0;
}

.content {
  display: flex;
  flex: 1;
}
