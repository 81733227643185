.wrapper {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: 5px;
}

.contentWrapper {
  display: flex;
  align-items: center;
  width: calc(100% - 16px);
}

.unread {
  font-weight: 500;
  color: #000;
}

.icon {
  position: relative;
  margin-right: 10px;
  max-width: 15px;
  width: 100%;
}

.icon img {
  width: 20px;
}

.content {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
