.header {
  padding-top: 10px;
  padding-bottom: 36px;
  margin-bottom: 50px;
  border-bottom: 1px solid #d9dbde;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.desc {
  font-size: 12px;
  margin-top: 14px;
  color: #979899;
}
