.profileButtonWrapper {
  position: relative;
  margin-left: 12px;
  display: none;
}

@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .active {
    display: inline-block !important;
  }
}
