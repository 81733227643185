.loginForm {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loginForm form {
  width: 360px;
  margin-bottom: 40px;
}

.loginForm h1 {
  margin: 0 0 40px 0;
  font-size: 24px;
  font-weight: 500;
}

.loginForm a {
  color: #969799;
  font-size: 14px;
  transition: all 0.2s ease;
}

.loginForm a:hover {
  color: #000;
}
