.attachments {
  display: flex;
  padding: 30px 24px 0 24px;
  flex-wrap: wrap;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 26px;
  font-size: 10px;
  color: #969799;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close:hover {
  color: #000;
}
