.container {
  position: relative;
  align-items: flex-start !important;
  justify-content: space-between !important;
  display: flex !important;
  width: 100%;
  margin-top: 80px;
  padding-bottom: 20px;
  /*z-index: 20;*/
}

.border {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -41px;
  padding-bottom: 60px;
  border-bottom: 1px solid #d9dbde;
}

.channelInfo {
  display: flex;
  width: 75%;
}

.logo {
  position: relative;
  display: flex;
  width: 100px !important;
  height: 100px !important;
  margin: 0 20px 0 0;
  padding: 0;
}

.logo img {
  display: inline-block;
  width: 100px !important;
  height: 100px !important;
}

.rightInfo {
  position: relative;
}

.title {
  position: relative;
  font-size: 20px;
  font-weight: 500;
  padding: 5px 0 20px 0;
}

.description {
  font-size: 14px;
  color: #646566;
}
