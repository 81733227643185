@import "./antd.css";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 1280px;
  min-height: 500px;
  font-size: 14px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

p {
  margin: 0;
  padding: 0;
}

textarea {
  font-size: 14px;
  font-weight: normal;
}

img {
  /* display: block; */
}

.ScrollbarsCustom > .wrapper {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.ScrollbarsCustom > .wrapper > .content {
  padding-right: 20px !important;
  margin-right: -20px !important;
  padding-bottom: 20px !important;
  margin-bottom: -20px !important;
}

.ScrollbarsCustom.hide-tracks .track {
  display: none;
}

.ScrollbarsCustom > .track {
  border-radius: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  transition: background 0.3s;
}

.ScrollbarsCustom > .trackY {
  height: 100% !important;
  top: 0 !important;
}

.ScrollbarsCustom > .trackX {
  width: 100% !important;
  left: 0 !important;
}

.ScrollbarsCustom:hover > .track {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.ScrollbarsCustom > .track > .thumb {
  opacity: 0;
  transition: opacity 0.3s;
}

.ScrollbarsCustom > .track > .thumb.dragging {
  padding: 0 !important;
  opacity: 0.7 !important;
}

.ScrollbarsCustom:hover > .track > .thumb {
  opacity: 0.5;
}

.track {
  z-index: 11 !important;
}

.activeDate {
  fill: #000;
  font-weight: 600;
  transition: all 0.2s ease;
}

.ant-select-selection-overflow-item {
  max-width: 97%;
}

.ant-select-selection-item .ant-tag {
  position: relative;
  top: -1px;
}

.ant-dropdown-menu-item {
  position: relative;
}

.ant-dropdown-menu-title-content > a:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
