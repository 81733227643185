.container {
  position: relative;
  display: flex;
  min-width: 210px;
  max-width: 360px;
  width: 100%;
}

.imageWrap {
  margin: 0 10px 0 0;
  padding: 0;
  height: 40px;
  min-height: 40px;
}

.imageWrap img {
  height: 100%;
  border-radius: 0;
}

.info {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}

.name {
  position: relative;
  text-decoration: none;
  margin: 0;
  padding: 0;
  line-height: 18px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: nowrap;
  color: #000;
  font-size: 14px;
}

.nameBlock {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
}

.extBlock {
  min-width: 24px;
}

.size {
  color: #969799;
  font-size: 12px;
}

.status {
  position: absolute;
  right: 0;
  bottom: 0;
}
