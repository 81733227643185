.teams {
  position: relative;
  display: flex;
  cursor: default;
  pointer-events: inherit;
  align-items: center;
}

.teamsAlone {
  overflow: hidden;
}

[class*="Table_tableRow"]:hover .team,
[class*="Table_tableRow"]:hover .next {
  border-color: #f2f4f7 !important;
}

.team {
  border: 2px solid #fff;
  display: block;
  position: relative;
  border-radius: 50%;
}

.teamAlone {
  display: flex;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.title {
  display: block;
  margin-left: 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tool [class^="Tooltip_tooltip"] {
  width: max-content !important;
  width: -moz-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
}

.nextTool {
  display: block;
  margin-bottom: 3px;
}

.next {
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  margin-left: -12px;
  width: 28px;
  height: 28px;
  border: 2px solid #ffffff;
  border-radius: 28px;
  background: #fff;
  position: relative;
}

.next::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  border: 1px solid #dbdde0;
}

.next span {
  position: absolute;
  bottom: 5px;
  left: 4px;
}

.two {
  width: 32px;
}

.two::before {
  width: 30px;
}

.three {
  width: 40px;
}

.three::before {
  width: 38px;
}
