.bubble {
  border: 1px solid #d9dbde;
  border-radius: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  position: relative;
}

.bubble.file {
  width: 100%;
  max-width: 360px;
}

.bubble + .bubble {
  margin-top: 4px;
}

.content {
  padding: 20px;
}

.errorWrap .content {
  opacity: 0.5;
}

.bubble img,
.bubble video {
  max-width: 100%;
  border-radius: 10px;
  display: flex;
}

.info {
  float: right;
  padding-left: 10px;
  display: flex;
  align-items: center;
  position: relative;
  top: 3px;
}

.noPadding {
  padding: 0;
}

.noBorder {
  border: none;
}

.dark {
  background: #f2f4f7;
  border: none;
}

.note {
  background: #f2ecce;
  border: none;
}

.tooltip {
  position: absolute;
  bottom: -14px;
  right: 20px;
  height: 24px;
  width: auto;
  max-width: 280px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: rgba(239, 240, 243, 0.902);
  border: 1px solid #d9dade;
  padding: 0 12px;
  font-size: 10px !important;
  color: #000 !important;
  box-shadow: 0 5px 5px 0 rgba(37, 40, 50, 0.05);
  transition: opacity 0.2s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  min-width: 40px;
  white-space: nowrap;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Source Sans Regular",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.bubble:hover .tooltip {
  opacity: 1;
  visibility: visible;
  z-index: 1060;
  transition: all 0.2s ease 0.4s;
}

.error {
  opacity: 0.5;
}
