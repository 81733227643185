// // VARIABLES

// $main-fonts: -apple-system, BlinkMacSystemFont, "Source Sans Regular", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

// // All colors
// $black: #000;
// $dark-100: #1a1e27;
// $dark-200: #1b1e26;
// $dark-300: #2d3140;
// $dark-400: #303133;
// $dark-500: #3d3d40;
// $dark-600: #3d3e40;
// $dark-700: #4b4b4d;
// $dark-800: #616366;
// $dark-900: #797c80;
// $light-100: #969799;
// $light-200: #afb0b3;
// $light-300: #bbbcbf;
// $light-400: #d5d6d9;
// $light-500: #edeff2;
// $light-600: #eff0f3;
// $light-700: #d9dbde;
// $light-800: #e1e3e6;
// $light-900: #dadcdf;
// $white: #fff;
// $green: #51b164;
// $yellow: #caad27;
// $red: #d30043;
// $blue: #5577f1;

// // Buttons

// // common button styles
// $btn-height: 40px;
// $btn-min-width: 100px;
// $btn-border-radius: 5px;
// $btn-font-size: 14px;
// $btn-padding: 0 20px 1px 20px;
// $btn-transition: all .2s ease;

// // primary button styles
// $btn-primary: #2d3140;
// $btn-primary-color: #fff;
// $btn-primary-hover: #1a1e27;
// $btn-primary-disabled: #797c80;
// $btn-primary-shadow: 0 0 0 2px #d5d6d9;

// // primary outline button styles
// $btn-outline-bg: #fff;
// $btn-outline: #d9dbde;
// $btn-outline-color: #000;
// $btn-outline-hover: #afb0b3;
// $btn-outline-focus: #afb0b3;
// $btn-disabled: #e1e3e6;
// $btn-outline-shadow: 0 0 0 2px #d5d6d9;

// // button sizes
// $btn-sm: 36px;
// $btn-df: 40px;
// $btn-lg: 48px;

// // dropdown
// $drp-bg: #fff;
// $drp-shadow: rgba(27, 30, 38, 0.03) 0 0 0 1px, rgba(27, 30, 38, 0.1) 0 8px 24px;
// $drp-li-hover: #eff0f3;

// // Main

// // inputs
// $form-gr-width: 100%;
// $input-height: 40px;
// $input-width: 100%;
// $input-border: #d9dbde;
// $input-hover-border: #afb0b3;
// $input-focus-shadow: 0 0 0 2px #e1e3e6;
// $input-border-radius: 5px;
// $input-font-size: 14px;
// $input-padding: 0 28px 0 12px;
// $input-transition: all 0.2s ease;
// $label-color: #9d9ea0;
// $label-hover-color: #000;
// $disabled-input: #edeff2;
// $clear-button-color: #7d7e80;
// $placeholder-color: #9d9ea0;

// // validation
// $input-default: #8c8d8e;
// $input-success: $green;
// $input-success-shadow: 0 0 0 2px rgba(220, 239, 224, 1);
// $input-warning: #caad27;
// $input-warning-shadow: 0 0 0 2px rgba(243, 238, 216, 1);
// $input-error: #d30043;
// $input-error-shadow: 0 0 0 2px rgba(248, 217, 223, 1);
// $header-title-size: 20px;

// // input sizes
// $input-sm: 36px;
// $input-df: 40px;
// $input-lg: 48px;

// // Scrollbar
// $scrollbar-size: 8px;
// $scrollbar-radius: 5px;
// $scrollbar-xy-color: rgba(0, 0, 0, 0.15);

// // Grid
// $gutter-width: 24px;

// Table
$table-row-border-color: rgba(0, 0, 0, 0.06);
$table-row-border-padding: 82px;
