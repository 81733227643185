.wrapper {
  position: relative;
}

.wrapper::before {
  position: absolute;
  content: "";
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
  background: rgba(55, 55, 55, 0.02);
  border: 1px #969696 dashed;
}

.isDrag::before {
  opacity: 1;
}

[data-react-beautiful-dnd-drag-handle],
[data-react-beautiful-dnd-draggable] {
  margin-bottom: 10px !important;
}

.wrapper > form > div > div {
  margin-bottom: 10px !important;
}
