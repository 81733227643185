.layout {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
}

.messages {
  flex: 1;
}

.details {
  border-left: 1px solid #d9dbde;
}

.overlay {
  display: none;
}

@media screen and (max-width: 1365px) {
  .details {
    display: none;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1519px) {
  .details {
    flex: 0 0 320px;
  }
}

@media screen and (min-width: 1520px) and (max-width: 1919px) {
  .details {
    flex: 0 0 360px;
  }
}

@media screen and (min-width: 1920px) {
  .details {
    flex: 0 0 400px;
  }
}
