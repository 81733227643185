.form {
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 5px;
  padding: 8px 12px;
  margin-bottom: 10px;
}

.input:last-of-type {
  margin-bottom: 0;
}

.input span {
  position: relative;
  padding-right: 30px;
}
