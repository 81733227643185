.replyingMessage {
  width: 100%;
  color: #000;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  left: 0;
  right: 0;
  padding: 40px 0 24px 0;
}
