.drop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  z-index: 10;
  background: #fff;
  flex-direction: column;
  display: none;
}

.active {
  display: block;
}

.wrapper {
  width: 100%;
  height: 100%;
  border: 1px dashed #d9dbde;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  margin: 0;
  padding: 16px 0;
  font-size: 16px;
}
