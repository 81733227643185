.modal :global(.ant-modal-body) {
  padding: 0;
}

.modal :global(.ant-tabs) {
  margin-top: -24px;
}

.modal :global(.ant-tabs-nav) {
  margin: 0 24px;
}

.modal :global(.ant-list) {
  padding: 8px 24px;
  height: 538px;
  max-height: calc(80vh - 240px);
  overflow: auto;
}
