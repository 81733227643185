.container {
  padding: 90px 40px 100px 40px;
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Source Sans Pro SemiBold",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.title {
  font-weight: 500;
  font-size: 24px;
  padding: 0 0 40px 0;
}

.subTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  margin: 0;
  border-bottom: 1px solid #d9dbde;
  color: #000000;
  font-weight: 500;
}
