.condition {
  display: flex;
  background: #f5f6fa;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.2s ease;
  position: relative;
}

.delete {
  opacity: 0;
  cursor: pointer;
  color: #7d7e80;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: calc(50% + 1px);
  transform: translateY(-50%);
  transition: all 0.2s ease;
}

.delete svg {
  fill: #838485;
  transition: all 0.2s ease;
}

.delete:hover svg {
  fill: #000;
}

.or {
  display: none;
  padding: 6px 9px;
  background: #fff;
  position: absolute;
  right: 10px;
  top: 0;
  transform: translateY(-50%);
  border-radius: 5px;
  font-size: 11px;
}

.condition + .condition {
  margin-top: 1px;
}

.condition + .condition .or {
  display: block;
}

.condition:hover {
  background: #edeef2;
}

.condition:hover .delete {
  opacity: 1;
}
