.info {
  position: absolute;
  right: 17px;
  bottom: 14px;
  background: #fff;
  padding: 1px 9px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
