.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desc {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px;
  max-width: 50%;
  font-size: 14px;
}

.text {
}

.timer {
}

.overText {
  display: block;
  padding-bottom: 15px;
}

.qr {
  position: relative;
  display: block !important;
  width: 250px !important;
  height: 250px !important;
  border-radius: 5px;
  border: 1px solid #d9dbde;
  overflow: hidden;
}

.image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: 0.2s ease;
}

.over {
  opacity: 0.3;
}
