.resendBlock {
  display: flex;
  height: 100%;
  width: 400px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto 40px auto;
}

.resendBlock h1 {
  margin: 0 0 40px 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.resendBlock span {
  font-size: 16px;
  color: #979898;
}

.resendBlock p {
  color: #000;
  margin-top: 6px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease;
}

.resendBlock p:hover {
  color: #979898;
}
