.content {
  position: relative;
}

.desc {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  color: #646566;
  font-size: 14px;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.buttons label {
  margin-bottom: 10px;
}

.horizontal {
  flex-direction: row;
}
