.detailsButtonWrapper {
  position: relative;
  margin-left: 12px;
  display: none;
}

@media screen and (max-width: 1365px) {
  .detailsButtonWrapper {
    display: inline-block;
  }
}
