.button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.svgPath {
  fill: #7d7e80;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.button svg {
  fill: #7d7e80;
  transition: all 0.2s ease;
}

.button:hover svg {
  fill: #000;
}
