.rule {
  border: 1px solid #d9dade;
  border-radius: 5px;
  padding: 40px 24px;
  transition: all 0.2s ease;
}

.title {
  color: #7d7e7f;
  display: flex;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1;
  align-items: center;
  transition: all 0.2s ease;
}

.title svg {
  fill: #838485;
  margin-right: 9px;
}

.body {
}

.rule:hover {
  border: 1px solid #afb0b3;
}

.rule:hover .title {
  color: #000;
}

.rule + .rule {
  margin-top: 20px;
}
