.sidebar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column !important;
  height: 100%;
  border-left: 1px solid #d9dbde;
}

@media screen and (max-width: 1365px) {
  .sidebar {
    border-left: none;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1519px) {
  .sidebar {
    flex: 0 0 320px;
  }
}

@media screen and (min-width: 1520px) and (max-width: 1919px) {
  .sidebar {
    flex: 0 0 360px;
  }
}

@media screen and (min-width: 1920px) {
  .sidebar {
    flex: 0 0 400px;
  }
}
