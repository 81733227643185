.headerText {
  font-size: 14px;
  color: #000;
  height: 24px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.headerText span {
  font-size: 24px;
  margin-right: 4px;
}
