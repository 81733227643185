.layout {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.cases {
  flex: 1;
}

.case {
  display: none;
  box-shadow: 0 0 50px 0 rgba(27, 30, 38, 0.1);
  z-index: 10;
}

.active .cases {
  flex: 0 0 320px;
  min-width: 0;
}

.active .case {
  display: block;
  flex: 1;
}

@media screen and (max-width: 1279px) {
  .active .cases {
    flex: 0 0 320px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1519px) {
  .active .cases {
    flex: 0 0 320px;
  }
}

@media screen and (min-width: 1520px) and (max-width: 1919px) {
  .active .cases {
    flex: 0 0 360px;
  }
}

@media screen and (min-width: 1920px) {
  .active .cases {
    flex: 0 0 400px;
  }
}
