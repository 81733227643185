.boxWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 !important;
  padding: 0 !important;
}

.box {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  border-radius: 5px;
}

.text {
  position: relative;
}
