.messages {
  padding: 60px 24px;
  min-height: 100%;
  box-sizing: border-box;
}

.noPadding {
  padding: 0;
}

.noPadding div[class*="line"] {
  width: calc(100% - 48px);
}

.messages div[class*="messageListEmail"]:last-child:after {
  border-bottom: none;
}

.messages div[class*="gmail_attr"] {
  color: #000 !important;
}

.messages div[class*="gmail_attr"] {
  display: none;
}

.messages div[class*="gmail_quote"] blockquote {
  display: none;
}

/*.messages div[class*="gmail_quote"] span {*/
/*font-size: 14px !important;*/
/*font-weight: 400 !important;*/
/*}*/

/*.messages div[class*="gmail_quote"] a {*/
/*font-size: 14px !important;*/
/*font-weight: 400 !important;*/
/*color: #4752b5 !important;*/
/*}*/

/*.messages div[class*="gmail_quote"] blockquote {*/
/*border-left: none !important;*/
/*margin: 0 !important;*/
/*padding: 6px 0 !important;*/
/*}*/
