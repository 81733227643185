.wrapper {
  display: flex;
  align-items: center;
}

.play,
.play:active,
.play:focus {
  background: #1890ff;
  color: #fff;
  margin-right: 8px;
}

.play:hover {
  background: #1890ff;
  color: #fff;
  opacity: 0.85;
}

.play:active {
  background: #1890ff;
  color: #fff;
  opacity: 0.85;
}

.sliderWrapper {
  flex: 1;
  justify-content: center;
}

.slider {
  flex: 1;
  margin-top: 5px;
  margin-bottom: 0;
}

.status {
  font-size: 13px;
  opacity: 0.85;
}
