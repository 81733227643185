.panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 80px;
  padding: 0 24px 0 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 20;
}

.panel.border {
  border-bottom: 1px solid #d9dbde !important;
}
