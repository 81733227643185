.button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #646466;
  transition: all 0.2s ease;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  margin-right: 8px;
  background-color: #edeef2;
  border-radius: 5px;
  transition: all 0.2s ease;
}

.icon svg {
  fill: #7d7e80;
}

.button:hover {
  color: #000;
}

.button:hover .icon {
  background-color: #e1e2e6;
}
