.button {
  margin-left: 12px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
  cursor: pointer;
  position: relative;
}

.input {
  display: none;
}

.button svg {
  fill: #7d7e80;
  transition: all 0.2s ease;
}

.button:hover svg {
  fill: #000;
}
