.field {
  width: 240px;
  display: flex;
  margin-right: 10px;
}

.timezone {
  width: 320px;
}

.isOperator {
  width: 160px;
}
