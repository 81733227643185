.container {
  position: absolute;
  right: 0;
  top: -42px;
  width: 60px;
  text-transform: uppercase;
}

.container div {
  font-size: 12px;
}
