.status {
  position: relative;
  font-size: 12px;
  color: #fff;
  width: 50px;
  border-radius: 20px;
  text-align: center;
  padding: 3px 0;
}

.red {
  background: rgb(192, 37, 70);
}

.yellow {
  background: rgb(202, 173, 40);
}

.grey {
  background: grey;
}
