@import "../../variables";

.wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.avatar {
  display: flex;
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  margin-left: 8px;
}
