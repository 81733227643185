.container {
  display: flex;
  justify-content: space-between;
}

.layout {
  height: 100%;
  background: #fff;
  position: relative;
  margin-left: 240px;
}

.example {
  margin-top: 80px;
  position: relative;
  width: 376px;
  min-width: 376px;
  min-height: 342px;
  height: calc(100vh - 195px);
  max-height: 690px;
}

@media (min-width: 1300px) {
  .example {
    position: sticky;
    top: 80px;
  }
}
