.divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
}

.line {
  background-color: #e1e3e6;
  height: 1px;
  width: 100%;
  top: 50%;
  position: absolute;
}

.children {
  position: relative;
  background: #fff;
  color: #bbbcbf;
  padding: 0 18px;
}

.isDate {
  margin: 60px 0;
}
