[class^="Table_tableRow"]:hover .drag {
  opacity: 1;
}

div[class*="Table_noHover"] .drag {
  opacity: 0 !important;
}

.drag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 10;
  height: 100%;
}

.drag svg {
  position: absolute;
  left: 0;
  width: 22px;
}

div[class*="Table_noHover"] .drag.active {
  opacity: 1 !important;
}

.drag:hover svg path,
.drag:active svg path {
  fill: #000000 !important;
}
