.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.emptyCol {
  position: relative;
  height: 100%;
  width: 10px;
}

.fields {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 590px;
  position: relative;
}

.field {
  position: relative;
  margin-bottom: 24px;
}

.disabled {
  pointer-events: none;
}

.disabled input {
  background-color: #edeff2;
  border: none !important;
  height: 38px;
  width: 100%;
  padding: 0 12px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.disabled [class^="Input_inputContainer"] {
  box-shadow: none !important;
  border: none !important;
  background: #edeff2;
}

.confirmed {
  position: absolute;
  top: 50%;
  right: 14px;
  color: #51b164;
  transform: translateY(-50%);
}