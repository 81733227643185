.content {
  display: flex;
  align-items: center;
}

.first,
.second {
  position: relative;
}

.first::before,
.second::before {
  position: absolute;
  content: "";
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
