.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  text-align: center;
}

.title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  /*padding-bottom: 15px;*/
}

.desc {
  padding-top: 6px;
  font-size: 14px;
}
