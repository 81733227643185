.close {
  color: #7d7e80;
  cursor: pointer;
  padding: 10px;
  position: relative;
  right: -10px;
  transition: all 0.2s ease;
}

.close:hover {
  color: #000;
}

.user {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.name {
  font-size: 14px;
  margin-left: 10px;
}
