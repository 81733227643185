.content {
  position: relative;
  right: -20px;
  height: 45px;
  user-select: none;
  width: 40px;
}

.content span[class^="Dropdown_icon"] {
  position: absolute;
  right: 20px;
}

.content:hover {
  cursor: pointer;
}
