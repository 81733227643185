.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.info {
  display: flex;
  align-items: center;
}

.name {
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
}

.status {
  position: relative;
  top: 3px;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #d9dbde;
}

.itemBlock {
  display: flex;
}

.item {
  margin-left: 10px;
}
