.card {
  display: flex;
  align-items: center;
}

.card img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
}

.extra {
  display: flex;
  align-items: center;
}

.desc {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.name {
  color: #000;
  font-size: 20px;
}

.role {
  color: #646566;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
