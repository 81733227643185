.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
}

.avatar img {
  display: block;
  object-fit: cover;
}

.assignName {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 8px;
  text-overflow: ellipsis;
}
