.wrapper {
  position: relative;
  display: flex;
  min-height: 40px;
  min-width: 210px;
  max-width: 360px;
  width: 100%;
}

.image {
  flex: 0 0 40px;
  max-width: 60px;
  max-height: 60px;
}

.image img {
  border-radius: 5px !important;
}

.image.sm {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.image.sm img {
  object-fit: contain;
  object-position: center;
  width: auto !important;
  border-radius: 0 !important;
}

.link {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

/*.info {*/
/*  min-width: 150px;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  margin-left: 10px;*/
/*  overflow: hidden;*/
/*  justify-content: center;*/
/*}*/

.info {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  text-decoration: none !important;
}

.name {
  position: relative;
  text-decoration: none;
  margin: 0;
  padding: 0;
  line-height: 18px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: nowrap;
  color: #000;
  font-size: 14px;
}

.nameBlock {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
}

.extBlock {
  min-width: 24px;
}

.size {
  color: #969799;
  font-size: 12px;
}

.status {
  position: absolute;
  right: 0;
  bottom: 0;
}
