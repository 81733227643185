.burger {
  width: 68px;
  min-width: 68px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.stripes {
  width: 20px;
  height: 13px;
  box-sizing: border-box;
}

.firstStripe,
.secondStripe,
.thirdStripe {
  background-color: #7d7e80;
  height: 1px;
  display: block;
  transition: all 0.2s ease;
}

.burger:hover .firstStripe,
.burger:hover .secondStripe,
.burger:hover .thirdStripe {
  background-color: #1b1e26;
}

.firstStripe,
.thirdStripe {
  width: 10px;
}

.secondStripe {
  width: 20px;
  margin: 5px 0;
}

.burger .firstStripe,
.burger .thirdStripe {
  transform: translateX(10px);
}

.rotate .firstStripe,
.rotate .thirdStripe {
  transform: translateX(0px);
}

.burger:active .firstStripe,
.burger:active .thirdStripe {
  width: 100%;
  transform: translateX(0px);
}
