.content {
  position: relative;
  margin-bottom: 45px;
  margin-top: 25px;
}

.inputHide {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  opacity: 0;
}

.content:hover .input {
  border-color: #afb0b3;
}
.content:hover .placeholder {
  color: #000;
}
.content:active .input {
  border-style: solid;
  box-shadow: 0 0 0 2px #e1e3e6;
}

.input {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px dashed #d9dbde;
  font-size: 14px;
  z-index: 10;
  transition: all 0.2s ease;
}

.input.error {
  border: 1px dashed #d30026;
}

.input::before {
  pointer-events: none;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 10;
}

.input:focus {
  border: 1px solid #afb0b3;
  box-shadow: 0 0 0 2px #e1e3e6;
}

.label {
  position: relative;
  display: flex;
  margin: 7px 0;
  color: #000;
  transition: all 0.2s ease;
  cursor: default;
  font-size: 14px;
}

.errors {
  position: relative;
  bottom: -5px;
  color: #d30026;
  font-size: 12px;
  font-weight: 400;
  z-index: 10;
}

.labelText {
  position: relative;
  padding-right: 10px;
  cursor: text;
  font-size: 14px;
  line-height: 1;
  pointer-events: none;
}

.placeholder {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #9d9ea0;
  transform: translate(-50%, -50%);
  z-index: 10;
  transition: 0.3s ease;
  white-space: nowrap;
  max-width: 380px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

.tooltip {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip svg path {
  fill: #777;
  transition: 0.3s ease;
}

.tooltip:hover svg path {
  cursor: pointer;
  fill: #000;
}

.clear {
  position: absolute;
  background: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  bottom: -1px;
  right: 0;
  width: 40px;
  height: 40px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s ease;
  z-index: 100;
}
.clear:hover {
  opacity: 1 !important;
}

.clear i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 8px;
}

.contentFill .label {
  color: #9d9ea0;
}

.contentFill .input {
  border-style: solid;
}

.contentFill .placeholder {
  color: #000;
  transition: 0s;
  left: 15px;
  transform: translate(0%, -50%);
}

.contentFill:hover .clear {
  opacity: 0.65;
}

.contentFill .clear {
  visibility: visible;
  pointer-events: all;
}
