.createNewPasswordForm {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.createNewPasswordForm form {
  width: 360px;
  margin-bottom: 40px;
}

.createNewPasswordForm h1 {
  margin: 0 0 40px 0;
  font-size: 24px;
  font-weight: 500;
}
