.avatars {
  display: flex;
  margin-right: 8px;
}

.avatar:last-child {
  margin-left: -6px;
  z-index: 1;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.avatar:first-child {
  margin-left: -2px;
  border: 2px solid transparent;
}

.label {
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 16px;
}

.backItem {
  display: flex;
  align-items: center;
  padding: 11px 12px 11px 12px;
  cursor: pointer;
}

.backItem:hover {
  background: #eff0f3;
}

.backItemArrow {
  color: #969799;
  font-size: 9px;
}

.backItemLabel {
  color: #969799;
  font-size: 11px;
  margin-left: 5px;
}

.textItem {
  padding: 13px 12px;
  font-size: 14px;
}
