.wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: 40px;
  min-width: 210px;
  max-width: 360px;
  width: calc(100% - 40px);
}

.icon {
  border-radius: 0 !important;
  width: 45px !important;
  min-width: 45px;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  margin-left: 10px;
}

.content {
  display: block;
  display: -webkit-box;
  text-decoration: underline;
  max-width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 18px;
  -webkit-line-clamp: 2;
  color: #5385ec;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  position: relative;
  display: inline-block;
  text-decoration: none !important;
  color: #969799;
}

.status {
  position: absolute;
  right: 0;
  bottom: 0;
}
