.link {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link svg path {
  fill: #5c5e66;
  transition: fill 0.2s ease;
}

.link:hover svg path {
  fill: #ffffff;
}

.active svg path {
  fill: #ffffff;
}
