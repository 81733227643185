.bubbles {
  width: 100%;
  max-width: 380px;
  min-width: 50px;
  margin: 0 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.alignRight {
  align-items: flex-end;
}

.tooltip {
  position: absolute;
  right: 0;
  bottom: -20px;
  height: 24px;
  width: auto;
  max-width: 280px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: rgba(239, 240, 243, 0.902);
  border: 1px solid #d9dade;
  padding: 0 12px;
  font-size: 10px;
  color: #000;
  box-shadow: 0 5px 5px 0 rgba(37, 40, 50, 0.05);
  transition: opacity 0.2s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  min-width: 40px;
  white-space: nowrap;
  box-sizing: border-box;
}

.bubbles:hover > .tooltip {
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 1060 !important;
}

.highlight > div {
  border: 2px solid #1890ff;
}
