.attachment {
  display: flex;
  position: relative;
  border: 1px solid #eff0f3;
  height: 48px;
  border-radius: 5px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: calc(33.3% - 1px);
  margin: 0 1px 2px 0;
  box-sizing: border-box;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*width: 80%;*/
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  color: #000;
  margin: 0;
  font-size: 11px;
}

.size {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #969799;
  margin: 0;
  font-size: 11px;
}

.uploaded {
  box-shadow: 0 1px 0.2px 0.02px #d9dbde;
  background-color: #eff0f3;
}

.deleteButton {
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 8px;
  color: #858688;
  cursor: pointer;
  content: "\e817";
  z-index: 3;
  transition: color 0.2s ease;
}

.deleteButton:hover {
  color: #000;
}

.attachment:hover .deleteButton {
  display: block;
}

.fileInfo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  /*flex-direction: column;*/
  padding: 10px 12px;
  box-sizing: border-box;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 100%;
}

.info {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  box-sizing: border-box;
}

.fileInfoUploaded {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 9px 12px;
  z-index: 2;
  box-sizing: border-box;
}

.loadingOverlay {
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: #eff0f3;
  transition: width 0.6s ease;
}

.preloaderWrapper {
  width: 24px;
  height: 24px;
  position: relative;
  margin-bottom: 4px;
}

.preloaderWrapper .deleteButton {
  display: block;
  position: absolute;
  left: 8px;
  top: 8.2px;
  font-size: 9px;
  z-index: 3;
}

.fileLoadingInfo {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 6px;
}

.percent {
  color: #969799;
  font-size: 11px;
}

.upload {
  color: #000;
  margin: 0;
  font-size: 11px;
}

.attachment.images .preview {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 1px;
  overflow: hidden;
}

.attachment.images .preview img {
  width: 100%;
  min-height: 24px;
}

.nameBlock {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 32px);
}

.extBlock {
  min-width: 24px;
}

/*error*/
.attachment.error {
  background-color: #f2dadf !important;
  border-color: #f2dadf !important;
  box-shadow: 0 1px 0.2px 0.02px #f1ced5;
}

.attachment.error .size {
  color: #d30043;
}

@media screen and (min-width: 1921px) {
  .attachment {
    width: calc(20% - 1px);
  }
}

@media screen and (max-width: 1440px) {
  .attachment {
    width: calc(50% - 1px);
  }
}
