.cardLinkContainer a {
  text-decoration: none;
}

.cardLinkContainer svg {
  fill: #7d7e80;
  transition: all 0.2s ease;
}

.cardLinkContainer:hover svg {
  fill: #000 !important;
}
