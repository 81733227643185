.content {
  display: flex;
  align-items: center;
}

.avatar {
  position: relative;
  margin-right: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  color: #000;
}

.email {
  color: #969799;
}
