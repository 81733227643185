.avatar {
  position: relative;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  transition: all 0.3s ease;
  overflow: hidden;
  background: #eff0f3;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

.avatar i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.avatar label {
  position: absolute;
  cursor: pointer;
  transition: opacity 0.3s ease;
  background: #eff0f3;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar label span {
  width: 100%;
  height: 100%;
}

.avatar input {
  visibility: hidden;
}

.avatar img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.avatar:hover label {
  opacity: 0;
}

.avatar img.pic {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px !important;
  height: 50px !important;
  object-fit: contain !important;
}

.personal {
  position: relative;
  display: flex;
  padding-top: 40px;
  padding-bottom: 30px;
  justify-content: space-between;
  /*padding: 32px 0 50px 320px;*/
  width: 100%;
}

.colAvatar {
  position: relative;
  width: 300px;
}

.inputList {
  width: 100%;
  max-width: 590px;
}

.input {
  position: relative;
}

.disabled input {
  box-shadow: none !important;
  background: #edeff2 !important;
  pointer-events: none !important;
}

.disabled [class^="Input_inputContainer"] {
  box-shadow: none !important;
  border: none !important;
  background: #edeff2;
}

.disabled input[name="email"] {
  height: 38px !important;
}

.preloader {
  top: calc(49.5% + 3px);
  transform: translateY(-50.5%);
  position: absolute;
  pointer-events: none;
}

.change {
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 14px;
  transition: 0.2s ease;
  color: #959595;
  transform: translateY(-50%);
}

.change:hover {
  color: #000;
}
