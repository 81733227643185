.card {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  display: flex;
  align-items: center;
}

.avatar {
  position: relative;
  margin-right: 12px;
}

.desc {
  position: relative;
  display: flex;
  flex-direction: column;
}

.name {
  color: #000;
  font-weight: 400;
  font-size: 20px;
}

.userId {
  position: relative;
  padding-left: 12px;
  color: #646566;
  font-size: 14px;
}

.item {
  padding-right: 10px;
}

.item:last-of-type {
  padding-right: 0;
}
