.wrapper {
  font-size: 22px;
  line-height: initial;
  font-weight: 500;
  display: flex;
  flex-wrap: nowrap;
  margin-right: 8px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Source Sans Pro SemiBold",
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  width: max-content !important; */
  /*max-width: 400px;*/
  /*min-width: 200px;*/
  /*width: 40%;*/
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/

  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  margin-right: 8px; */
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  margin-right: 8px;
}

@media screen and (max-width: 1519px) {
  .title {
    max-width: 120px;
  }
}
