.collapse {
  /*margin-bottom: 30px;*/
}
.collapse {
  position: relative;
  display: block;
}

/*.collapse:first-of-type {*/
/*    margin-top: 30px;*/
/*}*/

.content {
  display: none;
  padding: 20px 0;
}

.open .content {
  display: block;
}

.header {
  position: relative;
  user-select: none;
  cursor: pointer;
  margin: 0;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}

.header > i:before {
  transition: transform 0.2s ease;
}

.open .header > i:before {
  transform: rotate(180deg);
}
