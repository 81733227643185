.button {
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  right: -20px;
}

.left {
  right: auto;
  left: -20px;
}

.button svg {
  transition: all 0.2s ease;
  fill: rgb(125, 126, 128);
}

.button:hover svg {
  fill: #000;
}
