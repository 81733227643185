.messageListEmail {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #d9dbde;
  position: relative;
}

.messageListEmail:after {
  content: "";
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #d9dbde;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.messageListEmailItem {
  width: 100%;
  padding: 40px 24px;
  border-collapse: collapse;
  box-sizing: border-box;
  position: relative;
}

.messageListEmailItem.outgoing {
  padding: 40px 24px 40px 48px;
}

.sendIcon {
  display: none;
  position: absolute;
  top: 40px;
  left: 24px;
}

.messageListEmailItem.outgoing .sendIcon {
  display: inline-flex;
}

.emailHeader {
  height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 16px;
}

.emailHeader .avatarBlock {
  display: flex;
  margin-right: 10px;
}

.emailHeader .date {
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: #969799;
}

.emailBody {
  font-size: 14px;
  line-height: 18px;
}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.emailBody .infoBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.infoBlock .subject {
  font-weight: 600;
}

.senderInfo a {
  color: #4752b3;
  text-decoration: underline;
  margin-left: 4px;
  transition: color 0.2s ease;
}

.senderInfo a:hover {
  color: darkblue;
}

.attachmentText {
  color: #969799;
  font-size: 12px;
  height: 20px;
  margin-top: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  left: -4px;
}

.attachments {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
}
