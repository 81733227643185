.layout {
  height: 100%;
  background: #fff;
  position: relative;
}

.active {
  margin-left: 240px;
}

@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .active div[class*="sidebar"] {
    display: none !important;
  }
}
