.popup :global(.ant-cascader-menu .ant-cascader-menu-item-expand-icon) {
  top: 5px;
}

.popup :global(.ant-cascader-menu-item) {
  font-weight: normal !important;
  width: 214px;
}

.popup :global(.ant-cascader-menu-item-active) {
  font-weight: normal !important;
}

.popup :global(.ant-cascader-menu-item-disabled) {
  cursor: default;
}

.title {
  font-size: 12px;
  padding-top: 7px;
  pointer-events: none;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.45);
}

.popup :global(.ant-cascader-menu-item-disabled:first-child) .title {
  padding-top: 0px;
}

.item {
  padding: 5px 28px 5px 12px;
  margin: -5px -24px -5px -12px;
}
