.button {
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 32px;
  height: 32px;
  border: 1px solid #7d7e80;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  cursor: pointer;
  z-index: 101;
  background-color: #fff;
}

.button:hover {
  border: 1px solid #1b1e26;
}
