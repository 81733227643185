.button {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  user-select: none;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.button svg {
  fill: #7d7e80;
  transition: all 0.2s ease;
}

.button:hover svg {
  fill: #000;
}

:global(.emoji-mart-scroll) {
  overflow-y: hidden !important;
}

:global(.emoji-mart-scroll > div) {
  width: auto !important;
}

:global(.emoji-mart-emoji) {
  cursor: pointer;
}
