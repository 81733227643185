.status {
  margin-left: 5px;
  height: 12px;
}

.icon {
  display: block;
  width: 12px;
  height: 12px;
}
