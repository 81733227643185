.content {
  width: 100%;
  display: flex;
  align-items: center;
}

.content img {
  display: inline-block;
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.content span {
}
