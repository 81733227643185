.section {
  margin-top: 60px;
  margin-bottom: 30px;
}

.section:first-of-type {
  margin-top: 10px;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.desc {
  font-size: 14px;
  margin-top: 10px;
  color: #646566;
}
