@import "../../variables";

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableHeader {
  display: flex;
  z-index: 5;

  &.fixed {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
  }
}

.tableHeaderColumn {
  display: flex;
  width: 160px;
  min-width: 160px;
  height: 40px;
  align-items: center;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.85);
  flex-shrink: 0;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
  font-weight: 500;

  &:first-child {
    padding-left: 24px;
  }

  &.selectColumn {
    padding: 0;

    > label {
      width: 100%;
      height: 100%;
      padding-right: 20px;
      padding-left: 16px;
      display: flex;
      align-items: center;

      > span {
        top: 0 !important;
      }
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  &.sortable {
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }

    & .sorter {
      display: flex;
      flex-direction: column;
      color: #bfbfbf;
      margin-left: 8px;
      font-size: 11px;

      & .up {
        margin-bottom: -0.125em;
      }

      & .down {
        margin-top: -0.125em;
      }

      & .active {
        color: #2d3140;
      }
    }

    &:active {
      background: #f2f2f2;
    }
  }

  &.tableRowMenu {
    width: 50px;
    min-width: 50px;
  }
}

.tableBody {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tableRow {
  display: flex;
  position: relative;
  text-decoration: none;

  &.selected {
    .tableColumn {
      background-color: #f5f5f5;
    }
  }

  &:hover,
  &.active {
    .status {
      position: absolute;
      height: 100%;
      width: 3px;
      z-index: 1;
    }

    .tableColumn {
      background-color: #f5f5f5;
    }

    .tableRowMenu > .tableRowMenuIcon {
      opacity: 1;
    }
  }
}

.tableColumn {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  width: 160px;
  min-width: 160px;
  text-decoration: none !important;
  color: initial;
  height: 80px;
  padding: 0 12px;
  border-bottom: 1px solid $table-row-border-color;
  background: #fff;
  box-sizing: border-box;
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  &:first-child {
    padding-left: 24px;
  }

  &.selectColumn {
    padding: 0;

    > label {
      width: 100%;
      height: 100%;
      padding-right: 20px;
      padding-left: 16px;
      display: flex;
      align-items: center;

      > span {
        top: 0 !important;
      }
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  &.tableRowMenu {
    user-select: none !important;
    width: 50px;
    min-width: 50px;

    .tableRowMenuIcon {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .tableRowMenuContent {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      right: 0;
      cursor: pointer;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover .tableRowMenuIcon svg path {
      fill: #000;
    }
  }

  &.active {
    z-index: 35;

    .tableRowMenuIcon {
      opacity: 1;
    }
  }
}

.table.hoverable {
  .tableRow:hover .tableColumn {
    background: #f5f5f5;
  }
  .tableRow:hover .tableColumn.clickable:hover {
    background: #f1f1f1;
  }
}

.loader {
  display: flex;
  padding: 36px;
  justify-content: center;
}

.justified {
  flex: 1;
}

.p0 {
  padding: 0 !important;
}
