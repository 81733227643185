.wrapper {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  border-bottom: 1px solid #f0f0f0;
  transition: all 0.2s;
  color: #000;
}

.wrapper:hover {
  cursor: pointer;
  background: #f5f5f5;
  color: #000;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 16px;
  margin-top: 3px;
  width: 24px;
  height: 24px;
}

.icon > svg {
  //   width: 24px;
  //   height: auto;
  fill: #8c8c8c;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.actions {
  margin-bottom: 4px;
}

.action {
  opacity: 0.45;
  transition: all 0.2s;
}
