.wrapper {
  display: flex;
  align-items: center;
  width: calc(100% - 65px);
  height: 100%;
}

.assignedUser {
  display: block;
  position: absolute;
  right: 24px;
  top: 16px;
}

.date {
  position: absolute;
  right: 24px;
  bottom: 15px;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  font-size: 12px;
  color: #969799;
}
