.section {
  margin-bottom: 30px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  margin: 0;
  border-bottom: 1px solid #d9dbde;
  color: #000000;
  font-weight: 500;
}

.content {
  margin-top: 20px;
}
