.wrap div[class^="chrome-picker"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.wrap div[class^="chrome-picker"] > div:first-of-type {
  padding-bottom: 42% !important;
}

.wrap div[style^="width: 32px; text-align: right; position: relative;"] {
  display: none !important;
}
