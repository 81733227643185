.sendButton {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*background-color: #7d7e80;*/
  border-radius: 50%;
  margin-left: 12px;
  cursor: pointer;
}

.sendButton:hover {
  /*background-color: #1b1e26;*/
}

.sendButtonContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendButton svg {
  fill: #7d7e80;
  transition: all 0.2s ease;
}

.sendButton:hover svg {
  fill: #000;
}

.sendButton.disabled {
  pointer-events: none;
  opacity: 0.5;
}
