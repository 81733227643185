.formInput {
  display: flex;
  flex: 1;
}

.formTextarea {
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  padding: 22px 138px 20px 24px;
  font-size: 14px;
  line-height: 20px;
  -ms-overflow-style: none;
  box-sizing: border-box;
  display: block;
}

.formTextarea::-webkit-input-placeholder {
  color: #969799;
}

.formTextarea:-ms-input-placeholder {
  color: #969799;
}

.formTextarea::placeholder {
  color: #969799;
}
