.container {
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 10px;
}

.title {
  line-height: 24px;
}

.desc {
  text-decoration: none;
  transition: color 0.2s ease;
  color: #969799;
}

.desc:hover {
  color: #000;
  border-bottom: 1px solid #969799;
}
